module.exports = {
  angularModuleName: 'www',
  projectName: 'www',
  projectVersion: 'acc',
  environment: 'acc',
  release: 'www__undefined__2024-09-04T08:17:29.744Z',
  projectDescription: 'The public website of Katholiek Onderwijs Vlaanderen.',
  piwikAnalytics: {
    enabled: true,
    token: 'a7bfb5b5-b5b1-4789-bd80-7db893637d92',
  },
  apisAndUrls: {
    api: 'https://accapi.katholiekonderwijs.vlaanderen',
    contentApi: 'https://accapi.katholiekonderwijs.vlaanderen',
    cachedContentApi: 'https://acccached-api.katholiekonderwijs.vlaanderen',
    websitesApi: 'https://accapi.katholiekonderwijs.vlaanderen',
    cachedWebsitesApi: 'https://acccached-api.katholiekonderwijs.vlaanderen',
    pro: 'https://accpro.katholiekonderwijs.vlaanderen',
  },
  logging: {
    sentry: {
      enabled: true,
      url: 'https://7acc39d3601d4471be474308cb36ca96@o268751.ingest.sentry.io/5649941',
    },
  },
  prerender: {
    token: 'XviHbBzWrXNJwKRYTvpa',
  },
  trustedOrigins: [
    '*',
  ],
  proHomepage: '/content/c63847ea-be2e-44ec-abf1-ead0e4bac37f',
  website: '/web/sites/f8a0049e-d0c0-4bff-81f1-4403297395f3',
  defaultColor: '#808080',
  defaultIcon: 'https://cached-api.katholiekonderwijs.vlaanderen/content/28ec313a-7869-4ae6-a8ef-2bf23adaa708/attachments/veldicoontjes-08.svg',
};